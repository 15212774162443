import React, { useState, useRef, useEffect } from "react";
import * as Dapp from "@elrondnetwork/dapp";
import Transaction from "../views/transaction";
import styled from "styled-components";
import { device } from "../helpers/breakpoints";
import useGetTotalSupply from "../helpers/getTotalSupply";

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
const Background = styled.div`
  background-color: ${() => (inIframe() ? "transparent" : "#031672")};
  min-height: ${() => (inIframe() ? "" : "100vh")};
  display: flex;
  flex-direction: column;
  @media ${device.lg} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .wrapper-carrousel {
    position: relative;
    margin: auto;
    &::after {
      content: "";
      display: block;
      position: absolute;
      border: solid white 3px;
      width: 300px;
      height: 100%;
      border-radius: 32px;
      filter: blur(4px);
      top: 0px;
      left: 0px;
      right: 0px;
      margin: 0 auto;
      pointer-events: none;
      @media ${device.sm} {
        width: 700px;
      }
    }
  }
  .carousel {
    position: relative;
    width: 300px !important;
    .control-dots {
      display: none;
    }
    @media ${device.sm} {
      width: 700px !important;
    }

    border-radius: 32px;

    .slide {
      padding-bottom: 10px;
      position: relative;
      .flex {
        justify-content: center;
      }
      img {
        width: 200px;
        @media ${device.sm} {
          width: 325px;
        }

        &.second {
          display: none;
        }
        @media ${device.sm} {
          &.second {
            display: block;
          }
        }
      }
    }
  }
  .dots {
    display: block;
    position: absolute;
    margin: auto;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    .dot {
      background: white;
      width: 14px;
      height: 14px;
      border-radius: 20px;
      margin: 0px 10px;
      transition: transform 0.3s;
      cursor: pointer;
      &.current {
        transform: scale(1.6);
      }
    }
  }
`;

const CarrouselWrapper = styled.div``;

const MainLayout = ({ children }) => {
  const { loggedIn } = Dapp.useContext();
  const refreshAccount = Dapp.useRefreshAccount();

  React.useEffect(() => {
    if (loggedIn) {
      refreshAccount();
    }
  }, [loggedIn]);

  const routes = [
    {
      path: "/transaction",
      title: "Transaction",
      component: Transaction,
    },
  ];

  const [currentIndexCarrousel, setCurrentIndexCarrousel] = useState(0);
  const carrousel = useRef();
  let [total, isFinish] = useGetTotalSupply();

  return (
    <Background className="pb-4">
      <div className="route relative lg:w-full lg:max-w-[600px]">
        <Dapp.Authenticate
          routes={routes}
          unlockRoute="/" /* redirect here if user is not authenticated */
        >
          {children}
        </Dapp.Authenticate>
      </div>
    </Background>
  );
};

export default MainLayout;
