import {
  Transaction,
  GasLimit,
  GasPrice,
  Address,
  Balance,
  ChainID,
  TransactionVersion,
} from "@elrondnetwork/erdjs";

const utils = {
  getParameterByName: (name, url = window.location.href) => {
    const tmpName = name.replace(/[[]]/g, "\\$&");
    const regex = new RegExp(`[?&]${tmpName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return "";
    if (!results[2]) return "";
    const decodedUri = decodeURIComponent(results[2].replace(/\+/g, " "));
    return decodedUri || "";
  },
  intervalCountdown: (targetDate) => {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = targetDate - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // If the count down is finished, write some text
    if (distance < 0) {
      return false;
    }

    // Display the result in the element with id="demo"
    return `${days > 0 ? `${days}d` : ""} ${
      days > 0 || hours > 0 ? `${hours}h` : ""
    } ${minutes}m ${seconds}s `;
  },
  round: (value, decimals) => {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  },
  createTransactionFromRaw: (rawTransaction) => {
    return new Transaction({
      value: Balance.egld(rawTransaction.value),
      data: rawTransaction.payload,
      receiver: new Address(rawTransaction.receiver),
      gasLimit: new GasLimit(rawTransaction.gasLimit),
      gasPrice: new GasPrice(rawTransaction.gasPrice),
      chainID: new ChainID(rawTransaction.chainID),
      version: new TransactionVersion(rawTransaction.version),
    });
  },
  intToHex: (string) => {
    let hex = Number(string).toString(16);
    if (hex.length % 2 !== 0) {
      hex = `0${hex}`;
    }
    return hex;
  },
  stringToHex: (string) => {
    return string
      .split("")
      .map((c) =>
        c
          .charCodeAt(0)
          .toString(16)
          .padStart(2, "0")
      )
      .join("");
  },
  getTokenRealValue: (value, decimals) => {
    return value / 10 ** decimals;
  },
  truncateDecimals: (value, decimals) => {
    return (Math.floor(value * 10 ** decimals) / 10 ** decimals).toFixed(
      decimals
    );
  },
};

export default utils;
