import { SC } from "./walletConnectConfig";
import React, { useEffect, useMemo, useState } from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { io } from "socket.io-client";

import {
	TransactionPayload,
	SmartContract,
	Address,
	AddressValue,
	ContractFunction,
	ProxyProvider,
} from "@elrondnetwork/erdjs";

const socket = io("https://back-sale.theaquaverse.io", { transports: ['websocket'] });


const useGetTotalSupply = (max = 1000) => {
	const { network } = Dapp.useContext();
	const [total, setTotal] = useState(1000)
	window.setTotal = setTotal

	const isFinish = useMemo(() => {
		if (total === 2500) {
			return true
		} else {
			return false
		}
	}, [total])

	useEffect(() => {
		socket.on("setTotal", (attempt) => {
			setTotal(attempt)
			console.log(attempt)
		});
	}, []);

	return [total, isFinish]
};


export default useGetTotalSupply