import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, Link, useLocation } from "react-router-dom";
import * as Dapp from "@elrondnetwork/dapp";

import {
  TransactionPayload,
  SmartContract,
  Address,
  AddressValue,
  ContractFunction,
  ProxyProvider,
} from "@elrondnetwork/erdjs";

import { GiMagnifyingGlass } from "react-icons";

const Wrapper = styled.div`
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    color: white;
    background-clip: border-box;
    border-radius: 0.25rem;
  }
  .dapp-icon.icon-medium {
    width: 80px;
    height: 80px;
  }
  .dapp-icon {
    padding: 5px;
    border-radius: 50%;
    background-color: #fafafa;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .text-success {
    color: #28a745 !important;
  }
  .btn-primary {
    color: #fff;
    background-color: #1b46c2;
    border-color: #1b46c2;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: whtie;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 20rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;

export default function Transaction() {
  let succes = true;
  let history = useHistory();
  const { search } = useLocation();
  const { explorerAddress } = Dapp.useContext();
  const query = new URLSearchParams(search);
  const { status, txHash } = Object.fromEntries(query);

  return (
    <Wrapper>
      {status === "success" ? (
        <div class="card shadow-sm rounded p-5 border-0">
          <div class="card-body text-center d-flex flex-column justify-content-center">
            <div class="icon dapp-icon icon-medium">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check"
                class="svg-inline--fa fa-check h-8 fa-w-16 text-success fa-3x"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
            </div>
            <p class="text-xl font-bold mt-3">
              Transaction submitted successfully
            </p>
            <div class="mt-3 pb-3">
              <p>
                <a
                  href={`${explorerAddress}transactions/${txHash}`}
                  target="_blank"
                  class="tx-link"
                  title="View in Explorer"
                >
                  {txHash}
                </a>
                <img src="/magnifying-glass.png" className="w-4 ml-3" alt="" />
              </p>
              <Link class="btn btn-primary mt-3" to="/">
                Back to buy page
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div class="card shadow-sm rounded p-5 border-0">
          <div class="card-body text-center d-flex flex-column justify-content-center">
            <div class="icon dapp-icon icon-medium">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                class="svg-inline--fa fa-times h-8 text-red-500 text-3xl"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </div>
            <p class="h3 mt-3">Error sending transaction</p>
            <div className="flex">
              <a
                href={`${explorerAddress}transactions/${txHash}`}
                target="_blank"
                class="text-xs"
                title="View in Explorer"
              >
                {txHash}
              </a>
            </div>
            <div class="mt-3">
              <p>Try again</p>
              <div className="mt-4">
                <Link to="/" class="btn btn-primary bg-white">
                  Back to buy page
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
