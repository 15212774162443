import React from "react";
import { Route, Switch, useHistory, Link, useLocation } from "react-router-dom";
import * as Dapp from "@elrondnetwork/dapp";
import MainLayout from "./layouts/MainLayout";
import Login from "./views/Login";
import ConnectMaiar from "./views/connectors/ConnectMaiar";
import ConnectLedger from "./views/connectors/Ledger";
import Transaction from "./views/transaction";
import styled from "styled-components";
import { device } from "./helpers/breakpoints";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  max-width: 350px;
  margin: 0 auto;
  @media ${device.sm} {
    max-width: 550px;
  }
  .arrow-back {
    position: absolute;
    height: 30px;
    cursor: pointer;
    transition: transform 0.3s;
    z-index: 30;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

const Routes = () => {
  let history = useHistory();
  const location = useLocation();
  return (
    <MainLayout>
      <Wrapper className="pt-[60px] text-white">
        {location.pathname === "/" || location.pathname === "/transaction" ? (
          ""
        ) : (
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <img
              className="arrow-back top-[61px] left-[-105px]"
              src="/arrow-back.png"
              alt=""
            />
            <span className="text-white">Back</span>
          </span>
        )}
        <div>
          <p class="text-[24px] font-normal sm:pt-10">
            <span className="text-[#96FF9A] sm:text-white sm:text-[36px]">
              Scan the{" "}
              <span className="sm:text-[#96FF9A]">
                QR <span className="font-bold">code</span>
              </span>
              <br />{" "}
            </span>
          </p>
          <p className="font-light pt-8 sm:text-[20px] font-extralight">
            With Maiar application to connect to your wallet
          </p>
        </div>
        <Switch>
          <Route exact path="/">
            <Login></Login>
          </Route>
          <Route exact path="/wallet/connect">
            <ConnectMaiar />
          </Route>
          <Route exact path="/wallet/ledger">
            <ConnectLedger />
          </Route>
          <Route path="/transaction">
            <Transaction />
          </Route>
        </Switch>
      </Wrapper>
    </MainLayout>
  );
};

export default Routes;
