import React, { useState } from "react";
import * as Dapp from "@elrondnetwork/dapp";
import utils from "../../helpers/utils";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  .card-body {
    margin-bottom: 20px;
  }

  .mx-auto {
    margin: auto;
  }
  .login-container {
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  svg {
    margin: auto;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 30px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  h4 {
    padding-top: 20px;
  }
  .d-flex {
    display: flex;
    align-items: center;
    .mr-2 {
      margin-right: 4px;
    }
  }
  .lead {
    margin: 20px 0px;
  }
  .btn-primary {
    color: #fff;
    background-color: transparent;
    border-color: white;
  }
`;

const ConnectMaiar = () => {
  const [toRedirect] = useState(utils.getParameterByName("from"));
  return (
    <Wrapper className="wrapper">
      <Dapp.Pages.WalletConnect
        callbackRoute={`/${toRedirect}`}
        logoutRoute="/?buy"
        title=""
        lead=""
      />
    </Wrapper>
  );
};

export default ConnectMaiar;
