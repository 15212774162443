import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as Dapp from "@elrondnetwork/dapp";
import AppContext from "./AppContext";
import Routes from "./Routes";
import "./App.css";
import "./index.css";

function App() {
  const contextValue = {};

  return (
    <AppContext.Provider value={contextValue}>
      <Router>
        <Routes />
      </Router>
    </AppContext.Provider>
  );
}

export default App;
