import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useExtensionLogin } from "@elrondnetwork/dapp";
import * as Dapp from "@elrondnetwork/dapp";
import axios from "axios";

const LoginStyle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .wrapper-button-mint {
    width: 272px;
  }
  button {
    background: #083dc1;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 20px;
    border-radius: 30px;
    color: white;
    margin-top: 32px;
    transition: transform 0.5s;
    box-shadow: 0px 0px 40px rgba(8, 61, 193, 0.5);
    span {
      padding-left: 10px;
    }
    img {
      width: 36px;
      height: 20px;
      padding: 4px;
      background-color: white;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
`;

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

export default function Login() {
  const callbackRoute = "/";
  const token = "";
  const dapp = Dapp.useContext();

  const extensionLogin = useExtensionLogin({ callbackRoute, token });
  let history = useHistory();

  useEffect(() => {
    const params = {
      discordId: getQueryVariable("discord_id"),
      guilId: getQueryVariable("guil_id"),
      address: getQueryVariable("address"),
    };
    if (dapp.address || params.address) {
      window.parent.postMessage(
        { userLogin: true, erd: dapp.address || params.address },
        "*"
      );
      if (params.discordId) {
        axios.post(
          `https://aquaverse-thales-backend-develop-k5rhssucwa-ew.a.run.app/${params.guilId}/${params.discordId}/maiar`,
          {
            body: {
              maiar_wallet: dapp.address || params.address,
            },
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQHRoZWFxdWF2ZXJzZS5pbyIsImlhdCI6MTY0NjY2MjY0MCwiYXVkIjoiaHR0cHM6Ly9iYWNrZW5kLnRoZWFxdWF2ZXJzZS5pby8iLCJpc3MiOiJodHRwczovL3d3dy50aGVhcXVhdmVyc2UuaW8vIn0.9jGn7wYYwkDXWetWXjS-GYiNY40qHUbpMmnEPHV2EfI",
            },
          }
        );
      }
    }
    window.localStorage.clear();
  }, [dapp.address]);

  return (
    <LoginStyle className="text-white font-thin">
      <div>
        <p class="text-[24px] font-thing sm:text-[36px]">
          Connect <span className="font-bold text-[#96FF9A]">your wallet</span>
          <br />
          to view your NFT’s
        </p>
      </div>
      <p className="pt-8 font-">Please select your login method:</p>
      <div className="wrapper-button-mint">
        <button onClick={extensionLogin}>
          <img src="/img/wallet-connect/maiar.svg" alt="Logo Maiar" />
          <span>Maiar DeFi Wallet</span>
        </button>
        <button
          onClick={() => {
            history.push("/wallet/connect");
          }}
        >
          <img src="/img/wallet-connect/maiar.svg" alt="Logo Elrond" />
          <span>Maiar App</span>
        </button>
      </div>
    </LoginStyle>
  );
}
