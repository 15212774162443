import * as Dapp from "@elrondnetwork/dapp";
import styled from "styled-components";
const Wrapper = styled.div`
  text-align: center;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 70px;

  .mx-auto {
    margin: auto;
    padding: 10px 0px;
  }
  .state {
    svg {
      left: 0;
      right: 0;
      top: 0;
      bottom: -200px;
      margin: auto;
      position: absolute;
    }
  }
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  p {
    margin-top: 20px;
  }
  h4 {
    font-weight: 1000;
    margin-top: 40px;
  }
  button {
    margin: auto;
    margin-top: 24px;
    display: block;
    background-color: transparent;

    border: solid white 1px !important;
    font-weight: 1000;
    border: none;
    color: white;
    border-radius: 20px;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    transition: 0.2s;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export default () => {
  return (
    <Wrapper className="wrapper">
      <Dapp.Pages.Ledger callbackRoute="/earn" />
    </Wrapper>
  );
};
