import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  network,
  walletConnectBridge,
  walletConnectDeepLink,
} from "./helpers/walletConnectConfig";
import { NetworkConfig, ProxyProvider } from "@elrondnetwork/erdjs";
import * as Dapp from "@elrondnetwork/dapp";
import { CookiesProvider } from "react-cookie";
if (network?.gatewayAddress) {
  const proxyProvider = new ProxyProvider(network.gatewayAddress);
  NetworkConfig.getDefault().sync(proxyProvider);
}

ReactDOM.render(
  <React.StrictMode>
    <Dapp.Context
      config={{
        network, // provide connection information
        walletConnectBridge, // the server used to relay data between the Dapp and the Wallet
        walletConnectDeepLink, // link used to open the Maiar app with the connection details
      }}
    >
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Dapp.Context>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
