const walletConnectBridge = "https://bridge.walletconnect.org";
const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/";

export const dAppName = "Aquavers NFT";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 999999999;
export const version = 1;
export const gasLimit = 999999999;
export const gasPerDataByte = 1500;
export const SC = "erd1qqqqqqqqqqqqqpgq6arjq3awc3t8ue9z77cqyqzspyqu80zt940sadvcq4"

const network = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "xEGLD",
  walletAddress: "https://wallet.elrond.com/dapp/init",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com/",
};


export { walletConnectBridge, walletConnectDeepLink, network };
